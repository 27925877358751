//-----------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";

// Material ui Imports
import { useEffect, useState } from "react";
import { VTCube } from "@virtus-tech-repository/virtus-tech-repository";
import { isFirefox } from "react-device-detect";
import { useAppSelector } from "../../store/hooks";

// Custom components imports (and hooks and helpers)

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
export interface iSphereProps {
    video: HTMLVideoElement;
    setVideo: (video: HTMLVideoElement) => void;
    videoSrc?: string;
    setVideoCurrentTime?: (currentTime: number) => void;
    loading?: boolean;
    setVideoLoadingState?: (videoLoadingState: boolean) => void;
    handleVideoEnded?: () => void;
    autoLoop?: boolean;
    setVideoCurrentState?: (videoCurrentState: "Paused" | "Playing" | "Question" | undefined) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// VTSphere for immersive videos
export default function VTSphere({
    video,
    setVideo,
    videoSrc,
    setVideoCurrentTime = () => {},
    loading = false,
    setVideoLoadingState,
    handleVideoEnded,
    autoLoop = false,
    setVideoCurrentState,
}: iSphereProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    useFrame(() => {
        setVideoCurrentTime(video.currentTime);
    });

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE, USE MEMO ##

    const { allowAutoPlay } = useAppSelector((state) => state.currentReducer);
    const [videoLoaded, setVideoLoaded] = useState<boolean>(false);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    // Creates the video
    useEffect(() => {
        if (videoSrc) {
            let vid: HTMLVideoElement = document.createElement("video");
            vid.src = changeToLocalVideoSrc(videoSrc);
            vid.crossOrigin = "Anonymous";
            vid.loop = autoLoop;
            vid.autoplay = false;
            vid.muted =
                localStorage.getItem("video_muted") && localStorage.getItem("video_muted") === "true" ? true : false;

            // listener to check once loaded
            vid.addEventListener(
                "loadeddata",
                function () {
                    setVideoLoaded(true);
                    setVideo(vid);
                },
                false,
            );

            if (setVideoLoadingState) {
                vid.addEventListener(
                    "canplay",
                    function () {
                        setVideoLoadingState(false);

                        if (allowAutoPlay && setVideoCurrentState) {
                            setVideoCurrentState("Playing");
                            this.play();
                        }
                    },
                    false,
                );

                vid.addEventListener(
                    "waiting",
                    function () {
                        setVideoLoadingState(true);
                    },
                    false,
                );
            }
            if (handleVideoEnded) {
                vid.addEventListener(
                    "ended",
                    function () {
                        handleVideoEnded();
                    },
                    false,
                );
            }

            setVideo(vid);

            // Cleanup on leave
            return () => {
                vid.pause();
                vid.removeAttribute("src"); // empty source
                vid.load();
            };
        }
    }, [videoSrc]);

    function changeToLocalVideoSrc(videoSrc: string) {
        switch (videoSrc) {
            case "https://virtus-tech-media-api-test.s3.eu-west-1.amazonaws.com/virtus_tech/three_sixty_video/a08bd2b2-2560-4793-b3e5-4c859beefdc6/a08bd2b2-2560-4793-b3e5-4c859beefdc6.mp4":
                return "/water.mp4";
            case "https://virtus-tech-media-api-test.s3.eu-west-1.amazonaws.com/virtus_tech/three_sixty_video/97c66091-3644-4856-9f89-32cfe22f22e3/97c66091-3644-4856-9f89-32cfe22f22e3.mp4":
                return "/fire.mp4";
            default:
                return videoSrc;
        }
    }
    //-------------------------------------------------------------------------------------------------
    // ## Render ##
    // Returns default cube
    if (videoSrc === undefined) {
        return <VTCube />;
    }

    //-------------------------------------------------------------------------------------------------
    // Shows the sphere with the mapped video as a texture
    else if (videoLoaded && !loading) {
        return (
            <mesh rotation={[Math.PI, 0, 0]}>
                <sphereBufferGeometry args={[-200, 64, 800]} />
                <meshBasicMaterial>
                    <videoTexture encoding={THREE.sRGBEncoding} attach="map" args={[video!]} />
                </meshBasicMaterial>
            </mesh>
        );
    }

    //-------------------------------------------------------------------------------------------------
    // Shows loading cube
    else {
        return <VTCube loading />;
    }
}
