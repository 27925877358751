import { Auth } from "aws-amplify";
import { v4 as uuid } from "uuid";
import { emailToOrg } from "./converter";
import moment from "moment";

export enum AuthStateSignInResult {
    LOGIN_RESET_PASSWORD = "LOGIN_RESET_PASSWORD",
    LOGIN_TERMS_AND_CONDITIONS = "LOGIN_TERMS_AND_CONDITIONS",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_FAILURE = "LOGIN_FAILURE",
    LTI_LOGIN_FAIL = "LTI_LOGIN_FAIL",
    USER_DOES_NOT_EXIST = "USER_DOES_NOT_EXIST",
    USER_NOT_CONFIRMED = "USER_NOT_CONFIRMED",
}

export enum AuthStatePasswordChangeResult {
    SUCCESS,
    WRONG_CODE_ENETERD_ERROR,
    PASSWORD_TOO_WEAK,
    UNKNOWN_ERROR,
    USER_COGNITO_OBJECT_IS_NULL,
}

export enum AuthStateTermsAndConditionsResult {
    ACCEPTED,
    THERE_WAS_AN_ISSUE_ACCEPTING_TERMS_AND_CONDITIONS,
    USER_COGNITO_OBJECT_IS_NULL,
}

export enum AuthStateCheckUserAuthenticatedResult {
    AUTHENTICATED,
    ERROR,
}

type AuthStateInitialPasswordChangeResult = Exclude<
    AuthStatePasswordChangeResult,
    AuthStatePasswordChangeResult.WRONG_CODE_ENETERD_ERROR
>;

interface userCognitoObject {
    attributes?: {
        sub?: string;
    };
}

// aws auth helper using module pattern
export default (function () {
    let userCognitoObjectRef: any = null;

    return {
        signIn: async (
            email: string,
            password: string,
            onLoginSuccess: (userId: string, email: string, organisation: string, termsAndConditions?: string) => void,
        ): Promise<AuthStateSignInResult> => {
            try {
                // a successful response should be cognito user object
                const response = await Auth.signIn(email.toLowerCase(), password);
                userCognitoObjectRef = response;

                if ("challengeName" in response) {
                    if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
                        return AuthStateSignInResult.LOGIN_RESET_PASSWORD;
                    }
                }

                if (!("custom:terms_and_conditions" in response.attributes)) {
                    return AuthStateSignInResult.LOGIN_TERMS_AND_CONDITIONS;
                }

                onLoginSuccess(
                    response.attributes.sub,
                    response.attributes.email.toLowerCase(),
                    response.attributes["custom:organisation"],
                    response.attributes["custom:terms_and_conditions"],
                );

                return AuthStateSignInResult.LOGIN_SUCCESS;
            } catch (e) {
                if ((e as Error).message === "User does not exist." && process.env.REACT_APP_DEMO === "true") {
                    return AuthStateSignInResult.USER_DOES_NOT_EXIST;
                } else if ((e as Error).message === "User is not confirmed." && process.env.REACT_APP_DEMO === "true") {
                    return AuthStateSignInResult.USER_NOT_CONFIRMED;
                } else {
                    return AuthStateSignInResult.LOGIN_FAILURE;
                }
            }
        },

        checkUserExistsAndSendPasswordResetCode: async function (username: string): Promise<boolean> {
            try {
                await Auth.forgotPassword(username);
                return true;
            } catch {
                return false;
            }
        },

        createPasswordFirstTime: async function (password: string): Promise<AuthStatePasswordChangeResult> {
            try {
                if (userCognitoObjectRef !== null) {
                    await Auth.completeNewPassword(userCognitoObjectRef, password);
                    userCognitoObjectRef = null;
                    return AuthStatePasswordChangeResult.SUCCESS;
                }
                return AuthStatePasswordChangeResult.USER_COGNITO_OBJECT_IS_NULL;
            } catch (e) {
                // @ts-ignore
                const errorMesage: string = e.message;

                if (!!errorMesage.match(/password/gi)) {
                    return AuthStatePasswordChangeResult.PASSWORD_TOO_WEAK;
                }

                return AuthStatePasswordChangeResult.UNKNOWN_ERROR;
            }
        },
        resetPasswordIsSuccess: async function (
            username: string,
            code: string,
            password: string,
        ): Promise<AuthStatePasswordChangeResult> {
            try {
                await Auth.forgotPasswordSubmit(username, code, password);
                return AuthStatePasswordChangeResult.SUCCESS;
            } catch (e) {
                // @ts-ignore
                const errorMesage: string = e.message;
                if (errorMesage === "Invalid verification code provided, please try again.") {
                    return AuthStatePasswordChangeResult.WRONG_CODE_ENETERD_ERROR;
                }

                if (!!errorMesage.match(/password/gi)) {
                    return AuthStatePasswordChangeResult.PASSWORD_TOO_WEAK;
                }

                return AuthStatePasswordChangeResult.UNKNOWN_ERROR;
            }
        },

        checkUserCurrentlyAuthenticated: async function (
            OnFoundAuthenticatedUser: (userId: string, userEmail: string, organisation: string) => void,
        ): Promise<AuthStateCheckUserAuthenticatedResult> {
            try {
                // this is a cognito user object i believe
                const user = await Auth.currentAuthenticatedUser();

                if (user && user.attributes) {
                    const { sub, email } = user.attributes;

                    OnFoundAuthenticatedUser(sub, email.toLowerCase(), user.attributes["custom:organisation"]);
                    return AuthStateCheckUserAuthenticatedResult.AUTHENTICATED;
                }
                return AuthStateCheckUserAuthenticatedResult.ERROR;
            } catch {
                return AuthStateCheckUserAuthenticatedResult.ERROR;
            }
        },

        acceptTermsAndConditions: async function (): Promise<AuthStateTermsAndConditionsResult> {
            try {
                if (userCognitoObjectRef) {
                    await Auth.updateUserAttributes(userCognitoObjectRef, {
                        "custom:terms_and_conditions": "true",
                    });
                    return AuthStateTermsAndConditionsResult.ACCEPTED;
                }
                return AuthStateTermsAndConditionsResult.USER_COGNITO_OBJECT_IS_NULL;
            } catch (e) {
                return AuthStateTermsAndConditionsResult.THERE_WAS_AN_ISSUE_ACCEPTING_TERMS_AND_CONDITIONS;
            }
        },

        // null or object
        getUserCognitoObject: (): any => {
            return userCognitoObjectRef;
        },

        // read more about this Auth.confirmSignUp
        confirmUserSignedUp: async (username: string, authCode: string) => {
            try {
                const confirmSignUpResponse = await Auth.confirmSignUp(username.toLowerCase(), authCode);
                return true;
            } catch (e) {
                //@ts-ignore
                if (e.message === "Invalid verification code provided, please try again.") {
                    return false;
                }
            }
        },

        ltiLogin: async function (
            email: string,
            token: string,
            onLoginSuccess: (userId: string, email: string, organisation: string) => void,
        ): Promise<AuthStateSignInResult> {
            try {
                return await ltiSignIn(email, token, onLoginSuccess);
            } catch (err: any) {
                return AuthStateSignInResult.LTI_LOGIN_FAIL;
            }
        },

        // read more about this Auth.confirmSignUp
        signUpUser: async (email: string) => {
            try {
                const response = await Auth.signUp({
                    username: email.toLowerCase(),
                    password: "pass-1234",
                    attributes: {
                        "custom:organisation": "demo",
                        "custom:account_expiry": moment().add(14, "day"),
                    },
                });

                console.log("repsonse : ", response);
            } catch (e) {
                console.error("erros signing up", email, " error: ", e);
            }
        },
    };
})();

async function ltiSignIn(
    email: string,
    token: string,
    onLoginSuccess: (userId: string, email: string, organisation: string) => void,
): Promise<AuthStateSignInResult> {
    try {
        const response = await Auth.signIn(email.toLowerCase(), "");
        if (response.challengeName === "CUSTOM_CHALLENGE") {
            Auth.sendCustomChallengeAnswer(response, token).then((user) => {
                onLoginSuccess(
                    user.attributes.sub,
                    user.attributes.email.toLowerCase(),
                    user.attributes["custom:organisation"],
                );
                return AuthStateSignInResult.LOGIN_SUCCESS;
            });
        } else {
            return AuthStateSignInResult.LTI_LOGIN_FAIL;
        }
    } catch (err: any) {
        return AuthStateSignInResult.LTI_LOGIN_FAIL;
    }
    return AuthStateSignInResult.LTI_LOGIN_FAIL;
}
